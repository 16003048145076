import React, { useState, useContext, useEffect } from 'react';
import Modal from '../components/Modal';
// import { SessionContext } from "./sessions";
// import './Profile.scss'; 
// import { Api } from './Api';
import * as yup from "yup";
import { useForm } from 'react-hook-form';

import { useAPI, getUserProfile } from '../api';


const EditProfileSchema = yup.object().shape({
  bio: yup.string().required("Bio is required."),
});

const EditProfile = ({toggleModal, profileUser, setProfileUser}) => {

  const { register, errors, handleSubmit, setValue } = useForm({
    validationSchema: EditProfileSchema
  });


  const onSubmit = async data => {
    // const response = await Api(session, `${process.env.REACT_APP_API_URL}/users/${session.userID}`,  {
    //   verb: 'put',
    //   params: data
    // });

    // setProfileUser(response.current_user);
    // toggleModal();  
  }

  return (
    <>
      

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="modal-header flex-cont align-center">
          <h2 className="flex-1">Edit profile</h2>
          <div onClick={toggleModal} className="button close">X</div>
        </div>

        <div className="padding-1">
          <label htmlFor="bio">
            <textarea 
              className="full" 
              name="bio"
              ref={register}
              defaultValue={profileUser.bio}
            />
            {errors.bio && <div className="field-error">{errors.bio.message}</div>}
          </label>
          <br />
        </div>

        <div className="modal-footer align-center flex-cont">
          <input type="submit" value="Save" className="button" />
        </div>

      </form>

    </>
  )
}


const ProfileHome = () => {


  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const session = useContext(SessionContext);
  const [profileUser, setProfileUser] = useState(null);

  // const getUserProfile = query => {
  //   alert(`Get user profile ${JSON.stringify(query)}`);

  //   return {firstName: "Andy", lastName: "Simon"}
  // }


  const [ user, isLoading, error, retry ] = useAPI(getUserProfile, {query: {id: "66"}}); 


  // let url;
  // if (session.userID) {
  //   url = `${process.env.REACT_APP_API_URL}/users/${session.userID}`
  // }
  // else {
  //   url = ""
  // }
    
  useEffect(() => {
    fetchProfile();
    // console.log('response', response);
  }, []);

  const fetchProfile = async () => {
    // const r = await Api(session, url);
    // if (r) {
    //   console.log(r);
    //   setProfileUser(r.user);
    // }
    // else {
      
    // }
  }

  return (
    <>
      <h1>Profile</h1>

      {profileUser &&
        <>

          <div className="flex-cont align-center">
            <div className="flex-1 flex-cont align-center">
              <div className="avatar-cont">
              </div>
              <div className="margin-left-1">
                {profileUser.first_name} {profileUser.last_name}
              </div>
            </div>


            <>
              <div>
                <a onClick={toggleModal} className="font-1 red button" href="#">Edit profile</a>
              </div>

              {isModalOpen &&
                <Modal toggleModal={toggleModal}>
                  <EditProfile toggleModal={toggleModal} profileUser={profileUser} setProfileUser={setProfileUser} />
                </Modal>
              }
            </>
          </div>

          <div className="">
            <div>
              {profileUser.first_name} {profileUser.last_name}
            </div>
            <div>
              {profileUser.bio}
            </div>
          </div>
        </>
      }

    </>
  )
}

export default ProfileHome;