import { useState, useEffect } from "react";


const API_URL = "http://localhost:3011"
const queryString = require('query-string');



export const useAPI = (apiFunction, {query=null, payload=null}) => {
  // ---- State
  const [data, setData]           = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError]         = useState(null);

  // ---- API
  const fetchData = async () => {

    try {

      // console.log("...params", ...params);
      setIsLoading(true);
      const response = await apiFunction({query, payload});
      setData(response);
    } catch (e) {
      alert("fetchData ERROR" + e);
      setError(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => { 
    fetchData(); 
  }, []);

  return [ data, isLoading, error, fetchData ];
}


export const getUserProfile = async (params) => {
  const url = "/users/profile";
  const response = await andyFetch(url, params, "GET");

  alert("AFTER ANDY FETCH");
  return response;
};


const andyFetch = async (url, {query=null, payload=null}, method="GET") => {
  throw "sdfsdfsdf";
  const fullUrl = `${API_URL}/${url}.json?${queryString.stringify(query)}`;
  const token = "TEST TOKEN";

  let options = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token,
    }
  };

  if (payload && method !== 'GET') {
    options.body = JSON.stringify(payload);
  }

  const response = await fetch(fullUrl, options);

  console.log(`Response`, response);

  if (response.ok) {
    const json = await response.json();
    console.log("JSON response", json);
  } else {
    const e = await response.json();
    console.log(`throw`, response);
    throw e;
  }
};