import React from 'react';
import logo from './images/logo.svg';
import './styles/App.scss';
import { Link } from 'react-router-dom';

import store from './redux/store';


import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import HomeScreen from './screens/HomeScreen';
import JoinScreen from './screens/JoinScreen';
import ProfileHome from './screens/ProfileHome';

function App() {
  return (
    <Router>
      <div className="App">
        <nav className="narrow main">
          <div className="logo-cont">
            <Link to="/">
              {/* <img src={logo} alt="Fyzeak logo" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 556.55 94.88"><title>Asset 1</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path d="M0,94.88q.12-5.22.37-15.42Q.75,64.91.75,47.38q0-9.83-.5-31Q0,3.74,0,0H77.6V26.86H37.06l.12,16.29,37.06-1.24V64.17L37.18,62.92q0,12.31.25,32Z"/><path d="M105.41,68H129l4.23-16.29h-7.09l6.22-3.61q-4.47,9.45-10.32,12.81a26.74,26.74,0,0,1-13.56,3.36H94.09L87.75,32.08Q88.62,36.45,81.41,0h39.17l8.7,48h2.24l10.2-48h39.54L169.07,68q-3.23,13.19-11.44,20t-20.14,6.84H99.93Z"/><path d="M183.13,55.71l24.49-10,12.25-5q6.16-2.49,12.25-5V33.57q-13.92.38-24.18.56t-17.35.19c-1.33.08-2.55.13-3.67.13h-3V0h83.82V36.68l-24.5,11.2q-6.09,2.73-12.25,5.59t-12.25,5.6v2.11q-2.61,0,15.3-.25,10.69-.12,20.27-.25t14.54-.25V94.88H183.13Z"/><path d="M277.09,94.88q.38-9.33.56-22.13t.18-25.37q0-11.82-.18-24.75T277.09,0h78.84V26.86H314.27V38.18l38.17-1.25V58L314.27,56.7V68h41.78V94.88Z"/><path d="M358.86,94.88q1.36-7.09,2.8-14.55T364.58,65q3.24-16.67,6.28-33.21T376.52,0h64.79q7,39,14.92,79.58l3,15.3H420.29c-.25-2.15-.6-4.58-1.06-7.27S418.3,82,417.8,79q-3.36-20-5.59-36.8a291.28,291.28,0,0,1-2.61-30.47h-1.87a239.8,239.8,0,0,1-2.49,31.46q-2.36,16.79-6.46,41.16-.63,3.36-1.87,10.57Zm41.29-33.95h17.16l3.6,18.41H396.54Z"/><path d="M498.35,47.5q.12,6.84.31,15.61t.44,17.59c.08,3.16.16,5.93.25,8.34s.12,4.35.12,5.84H462q.61-26.49.62-47.38T462,0h37.43c-.08,2.49-.19,6-.31,10.51s-.23,9.55-.31,15.11Q498.72,32.21,498.35,47.5Zm2.36-5L516.51,0H555.8l-14,35.32A14.46,14.46,0,0,1,538,41.1,16.92,16.92,0,0,1,531,44q-4.79,1-15.85,2.42v1.87q11.31,1.24,16,2.24c3.11.66,5.34,1.61,6.71,2.86a17.91,17.91,0,0,1,3.92,6.21l14.8,35.32h-41L500.59,53h-4.1V42.53Z"/></g></g></svg>
            </Link>
          </div>
          <div className="nav-links-cont">
            {true ? (
                <>
                  <Link className="text-link" to="/coaches">Coaches</Link> 
                  <Link className="text-link" to="/profile">My Profile</Link> 
                </>
              ) : (
                <>
                  <Link to="/login" className="text-link">Login</Link>
                  <Link to="/join" className="button">Join Free</Link>
                </>
              )
            }
          </div>
        </nav>

        <main>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/join" element={<JoinScreen />} />
            <Route path="/profile" element={<ProfileHome />} />
          </Routes>


          <pre>
            {JSON.stringify(store.getState(), null, 2)}
          </pre>
        </main>

        <footer>
          <pre style={{width: '90vw', wordWrap: "break-word"}}>
            
          </pre>
        </footer>
      </div>
    </Router>
  );
}

export default App;
