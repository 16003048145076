
// import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
// import thunk from 'redux-thunk';
// import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';


const currentUser = localStorage.getItem('currentUser');
const testAuthToken = localStorage.getItem('testAuthToken');

const initialState = {
  currentUser: currentUser ? JSON.parse(currentUser) : null,
  testAuthToken: testAuthToken ? JSON.parse(testAuthToken) : null,
};

const sessionReducer = ( state = initialState, action) => {
  switch(action.type) {
    case "SET_CURRENT_USER":
      return {
        currentUser: action.currentUser,
        testAuthToken: action.testAuthToken,
      }
    case "UNSET_CURRENT_USER":

      localStorage.removeItem('currentUser');
      localStorage.removeItem('testAuthToken');
      return {
        currentUser: null,
        testAuthToken: null,
      }
    default :
      return state
  }
}

export default sessionReducer;