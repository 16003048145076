import React, { useState, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

const SignupSchema = yup.object().shape({
  email: yup.string().required("Email is required."),
  first_name: yup.string().required("First name is required."),
  last_name: yup.string().required("Last name is required."),
  password: yup.string().min(6, "Password must be at least 6 characters long."),
  password_confirmation: yup.string().oneOf([yup.ref('password')], "Passwords don't match."),
  weight_lb: yup.number().when('meas', { is: 'imperial', then: s => s.required("Weight can't be blank.").typeError("Weight must be a number.").min(0).max(999) }),
  weight_kg: yup.number().when('meas', { is: 'metric', then: s => s.required("Weight can't be blank.").typeError("Weight must be a number.").min(0).max(999) }),
  height_cm: yup.number().when('meas', { is: 'metric', then: s => s.required("Height can't be blank.").typeError("Height must be a number.").min(0).max(999) }),
  height_ft: yup.number().when('meas', { is: 'imperial', then: s => s.required("Feet can't be blank.").typeError("Feet must be a number.").min(0).max(999) }),
  height_in: yup.number().when('meas', { is: 'imperial', then: s => s.required("Inches can't be blank.").typeError("Inches must be a number.").min(0).max(999) }),
  meas: yup.string().oneOf(["imperial", "metric"]).required(),
  sex: yup.string().oneOf(["male", "female"]).required(),
  birthday: yup.date().required("Birthday can't be blank.").typeError("Birthday must be in format YYYY-M-D"),

  // is_coach: yup.bool().when('is_athlete', { is: false, then: s => s.oneOf([true], "You must ") }),
  // is_athlete: yup.bool().when('is_coach',   { is: false, then: s => s.oneOf([true]) }),

});

const JoinScreen = props => {

  let navigate = useNavigate();

  const [meas, setMeas] = useState('metric');
  const [vvv, setVvv] = useState();

  // const session = useContext(SessionContext);

  const { watch, register, errors, handleSubmit, getValues } = useForm({
    validationSchema: SignupSchema
  });


  const onSubmit = async data => {
    alert('here');
    // const response = await Api(session, `${process.env.REACT_APP_API_URL}/signup`, {
    //   verb: 'post',
    //   params: data
    // })
    
    // session.loginUser(response);
    // navigate('/profile');
    // history.push("/profile");

  }

  const andy = watch("meas");

  const onChangeMeas = () => {
    setMeas(getValues().meas);
    setVvv(getValues());
    // clearError(['height_cm', 'height_in', 'height_ft', 'weight_kg', 'height_lb']); 

    // if (meas === 'metric') {
    //   register({name: 'height_cm'});
    //   register({name: 'weight_kg'});

    //   unregister({name: 'height_in'});
    //   unregister({name: 'height_ft'});
    //   unregister({name: 'height_lb'});
    // }
    // else {
    //   unregister({name: 'height_cm'});
    //   unregister({name: 'weight_kg'});

    //   register({name: 'height_in'});
    //   register({name: 'height_ft'});
    //   register({name: 'height_lb'});
    // }


    // clearError(['height_a', 'height_b', 'weight']); 
    
  }

  
  return(
    <div className="narrow">
      <h1>Join</h1>

      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="flex-cont">
          <label htmlFor="email"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Email"
              name="email"
            />
            {errors.email && <div className="field-error">{errors.email.message}</div>}
          </label>
        </div>
        
        <div className="flex-cont">
          <label htmlFor="firstName"> 
            <input 
              type="text" 
              ref={register}
              placeholder="First Name"
              name="first_name" 
            />
            {errors.first_name && <div className="field-error">{errors.first_name.message}</div>}
          </label>
          <label htmlFor="lastName"> 
            <input 
              type="text" 
              ref={register}
              placeholder="Last Name"
              name="last_name"
            />
            {errors.last_name && <div className="field-error">{errors.last_name.message}</div>}
          </label>
        </div>

        <div className="flex-cont">
          <label htmlFor="password"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Password"
              name="password"
            />
            {errors.password && <div className="field-error">{errors.password.message}</div>}
          </label>
          <label htmlFor="password_confirmation"> 
            <input 
              type="password" 
              ref={register}
              placeholder="Retype Password"
              name="password_confirmation"
            />
            {errors.password_confirmation && <div className="field-error">{errors.password_confirmation.message}</div>}
          </label>
        </div>

      
        <label>
          <input type="checkbox" name="is_coach" ref={register} />
          <span>I am a coach.</span>
          {errors.is_coach && <div className="field-error">{errors.is_coach.message}</div>}
        </label>
        <br />
        <label>
          <input type="checkbox" name="is_athlete" ref={register} />
          <span>I am an athlete.</span> 
          {errors.is_athlete && <div className="field-error">{errors.is_athlete.message}</div>}
        </label>
        <br />
        <br />
        <label>
          <input onChange={onChangeMeas} type="radio" ref={register} name="meas" value="metric" defaultChecked={true}  />
          <span>Metric (cm, kg)</span>
        </label>
        <br />
        <label>
          <input onChange={onChangeMeas} type="radio" ref={register} name="meas" value="imperial"  />
          <span>Imperial (in, lb)</span>
        </label>
        <br />
        <br />

        {andy !== "imperial" &&
            <>
              <div className="flex-cont">
                <label> 
                  <input 
                    type="text" 
                    ref={register}
                    placeholder="Height (centimeters)"
                    name="height_cm"
                  />
                  {errors.height_cm && <div className="field-error">{errors.height_cm.message}</div>}
                </label>

                <label> 
                  <input 
                    type="text" 
                    ref={register}
                    placeholder="Weight (kilograms)"
                    name="weight_kg"
                  />
                  {errors.weight_kg && <div className="field-error">{errors.weight_kg.message}</div>}
                </label>
              </div>
            </>
       }
       {andy === "imperial" &&
       
            <>
              <div className="flex-cont">
                <label> 
                  <input 
                    type="text" 
                    ref={register}
                    placeholder="Height (feet)"
                    name="height_ft"
                  />
                  {errors.height_ft && <div className="field-error">{errors.height_ft.message}</div>}
                </label>
                <label> 
                  <input 
                    type="text" 
                    ref={register}
                    placeholder="Height (inches)"
                    name="height_in"
                  />
                  {errors.height_in && <div className="field-error">{errors.height_in.message}</div>}
                </label>
                <label> 
                  <input 
                    type="text" 
                    ref={register}
                    placeholder="Weight (pounds)"
                    name="weight_lb"
                  />
                  {errors.weight_lb && <div className="field-error">{errors.weight_lb.message}</div>}
                </label>
              </div>
            </>
          
        }

        <label>
          <input type="radio" ref={register} name="sex" value="female" defaultChecked={true}  />
          <span>Female</span>
        </label>
        <br />
        <label>
          <input type="radio" ref={register} name="sex" value="male"  />
          <span>Male</span>
        </label>
        {errors.sex && <div className="field-error">{errors.sex.message}</div>}
        <br />
        <br />
        <br />
        <label>
          <span>Birthday</span>
          <br />
          <DayPickerInput inputProps={{name: "birthday", ref: register}} type="text"  />
          {errors.birthday && <div className="field-error">{errors.birthday.message}</div>}
        </label>
        <br />
        <br />
        <input type="submit" value="Join" />
      </form>
    </div>
    
  )
};

export default JoinScreen;